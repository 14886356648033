export const RoutePaths = {
  SiteRootRoute: {
    root: '/',
    rootForModule: '',
  },
  ErrorRoute: {
    root: 'error',

    notFound: 'not-found',
    notFoundCode: '404',

    forbidden: 'forbidden',
    forbiddenCode: '403',

    badRequest: 'bad-request',
    badRequestCode: '400',

    serverError: 'server-error',
    serverErrorCode: '500',
  },
};
